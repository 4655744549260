<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4">
            <input
              type="search"
              class="form-control"
              placeholder="Pesquisar..."
              v-model="filter"
            />
          </div>
        </div>
      </div>

      <b-table
        sticky-header
        :no-border-collapse="true"
        class="position-relative"
        :items="warnings.data"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        striped
        hover
      >
        <template #cell(start_date)="data">
          {{ moment(data.item.start_date).format("DD/MM/YYYY HH:mm") }}
        </template>
        <template #cell(end_date)="data">
          {{ moment(data.item.end_date).format("DD/MM/YYYY HH:mm") }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="data.item.status ? 'success' : 'danger'">
            {{ data.item.status ? "Ativo" : "Inativo" }}
          </b-badge>
        </template>
        <template #cell(warning_type)="data">
          <b-badge :style="{ backgroundColor: data.item.warning_color }">
            {{ data.item.warning_type }}
          </b-badge>
        </template>
        <template #cell(title)="data">
          <div class="d-flex justify-content-start" style="column-gap: 15px">
            <b-avatar
              :src="data.item.product_image"
              variant="light-primary"
              rounded=""
            />
            <div class="chat-info flex-grow-1">
              <h5 class="mb-0">
                {{ data.item.title }}
              </h5>
              <p class="card-text text-truncate">
                {{ data.item.product_category_initials }} -
                {{ data.item.product_title }}
              </p>
            </div>
          </div>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT }}
        </template>

        <template #cell(reads)="data">
          <b-badge variant="light-dark">
            {{ data.item.reads }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="openEditModal(data.item)"
            />

            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="openViewModal(data.item)"
            />
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class=""> Foram encontrados {{ totalRows }} resultados </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="updatePage()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      centered
      id="modal-create-warning"
      :title="setTitleModal()"
      hide-footer
      no-close-on-backdrop
    >
      <b-card-text>
        <form
          ref="formCreateUser"
          class="p-0"
          @submit.prevent="submitWarning(mode)"
        >
          <div class="form-row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Título
                </label>
                <input
                  :disabled="mode === 'view'"
                  type="text"
                  v-model="currentItem.title"
                  minlength="5"
                  name="title"
                  class="form-control"
                  :class="{ 'is-invalid': $v.currentItem.title.$error }"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for=""> Descrição </label>
                <input
                  :disabled="mode === 'view'"
                  type="text"
                  v-model="currentItem.description"
                  minlength="5"
                  name="description"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Status
                </label>
                <v-select
                  :disabled="mode === 'view'"
                  v-model="currentItem.status"
                  :options="optionsStatus"
                  placeholder="Selecione o status"
                  label="name"
                  :clearable="false"
                  :reduce="(option) => option.id"
                  :class="{ 'is-invalid': $v.currentItem.status.$error }"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Tipos
                </label>
                <v-select
                  :disabled="mode === 'view'"
                  v-model="currentItem.type"
                  :options="optionsTypes"
                  :class="{ 'is-invalid': $v.currentItem.type.$error }"
                  placeholder="Selecione o tipo"
                  label="name"
                  :clearable="false"
                  :reduce="(option) => option.id"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                  <template v-slot:option="option">
                    <b-badge
                      :style="{
                        backgroundColor: option.color_hex || '#ffc107',
                      }"
                    >
                      {{ option.name }}
                    </b-badge>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-md-12">
              <i class="text-danger bi bi-record-circle"></i> Slug
              <b-form-group label-for="slug">
                <b-form-input
                  id="slug"
                  v-model="currentItem.slug"
                  :class="{ 'is-invalid': $v.currentItem.slug.$error }"
                  @input="fetchSlugs"
                  placeholder="Crie ou selecione um slug..."
                  list="slug-options"
                ></b-form-input>
                <datalist id="slug-options">
                  <option
                    v-for="option in filteredSlugs"
                    :key="option"
                    :value="option"
                  ></option>
                </datalist>
              </b-form-group>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Data de Exibição
                </label>
                <flat-pickr
                  v-model="currentItem.active_date"
                  :disabled="mode === 'view'"
                  class="form-control"
                  placeholder="Exibir em"
                  :config="{
                    mode: 'range',
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    locale: 'pt',
                  }"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for=""> Imagem </label>
                <input
                  type="text"
                  :disabled="mode === 'view'"
                  v-model="currentItem.image"
                  name="text"
                  placeholder="https://..."
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for=""> Link </label>
                <input
                  type="text"
                  v-model="currentItem.link"
                  placeholder="https://"
                  :disabled="mode === 'view'"
                  name="link"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for="">
                  <!-- <i class="text-danger bi bi-record-circle"></i> -->
                  Produto
                </label>
                <v-select
                  label="title"
                  item-text="title"
                  :disabled="mode === 'view'"
                  item-value="code"
                  v-model="currentItem.product"
                  placeholder="Digite o título"
                  :options="optionsProducts"
                  @search="fetchProducts"
                  @input="productSelected"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for="">
                  <!-- <i class="text-danger bi bi-record-circle"></i> Turma -->
                </label>
                <v-select
                  label="title"
                  item-text="title"
                  :disabled="mode === 'view'"
                  item-value="code"
                  v-model="currentItem.classroom"
                  placeholder="Digite o título"
                  :options="optionsClassrooms"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
          </div>

          <div class="form-row justify-content-end mt-2 pb-0">
            <div class="col-md-3">
              <button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="btn btn-block btn-outline-secondary"
                block
                @click="hideModal()"
              >
                Fechar
              </button>
            </div>
            <div v-if="mode !== 'view'" class="col-md-4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-2"
                type="submit"
                block
                :disabled="submited"
              >
                <div v-if="submited">
                  <b-spinner small variant="light" /> Verificando...
                </div>
                <div v-else>
                  {{ mode === "create" ? "Cadastrar" : "Salvar Alterações" }}
                </div>
              </b-button>
            </div>
          </div>
        </form>
      </b-card-text>
    </b-modal>

    <b-modal centered id="modal-show-warning" v-if="item" hide-footer>
      <template #modal-title>
        {{ item.title }}
        <b-badge
          :style="{ backgroundColor: item.warning_color || '#ffc107' }"
          class="ml-2"
        >
          {{ item.warning_type }}
        </b-badge>
      </template>

      <b-card>
        <b-card-text>
          <form ref="formCreateUser" class="p-0">
            <div class="form-row">
              <!-- Descrição -->
              <div class="col-md-12">
                <div class="form-group">
                  <strong>Descrição:</strong>
                  <span v-html="item.description"></span>
                </div>
              </div>

              <!-- Produto -->
              <div class="col-md-12">
                <div class="form-group">
                  <strong>Produto:</strong>
                  {{ item.product_title || "Não informado" }}
                </div>
              </div>

              <!-- Categoria do Produto -->
              <div class="col-md-12">
                <div class="form-group">
                  <strong>Categoria:</strong>
                  {{ item.product_category_name || "Não informado" }}
                </div>
              </div>

              <!-- Período do Aviso -->
              <div class="col-md-12">
                <div class="form-group">
                  <strong>Período:</strong>
                  {{ formatDate(item.start_date) }} -
                  {{ formatDate(item.end_date) }}
                </div>
              </div>

              <!-- Criado em -->
              <div class="col-md-12">
                <div class="form-group">
                  <strong>Criado em:</strong>
                  {{ formatDate(item.created_at) }}
                </div>
              </div>

              <!-- Link do Aviso -->
              <div class="col-md-12" v-if="item.link">
                <div class="form-group">
                  <b-button variant="primary" :href="item.link" target="_blank">
                    Acessar Link
                  </b-button>
                </div>
              </div>
            </div>
          </form>
        </b-card-text>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BSidebar,
  BForm,
  BFormGroup,
  BFormRow,
  BSpinner,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { required, minLength } from "vuelidate/lib/validators";
import Editor from "@tinymce/tinymce-vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import Feather from "@/views/ui/feather/Feather.vue";

export default {
  components: {
    BCardText,
    BSidebar,
    BFormGroup,
    BFormInput,
    BSpinner,
    BCard,
    BRow,
    BForm,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BFormRow,
    BFormGroup,
    flatPickr,
    vSelect,
    editor: Editor,
    Feather,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    moment,
    submited: false,
    filter: null,
    filterOn: [],
    currentPage: 1,
    perPage: 100,
    totalRows: 0,
    rowsTable: 0,
    warnings: [],
    perPageOptions: [
      {
        title: "100",
        value: 100,
      },
      {
        title: "200",
        value: 200,
      },
    ],
    item: {
      title: "",
      description: "",
      product: "",
      product: "",
      classroom: "",
    },
    currentItem: {},
    tableColumns: [
      {
        key: "title",
        label: "Título",
        sortable: false,
      },
      {
        key: "slug",
        label: "Slug",
        sortable: false,
        class: "text-center",
      },
      {
        key: "warning_type",
        label: "Tipo",
        sortable: false,
        class: "text-center",
      },
      {
        key: "status",
        label: "Status",
        sortable: false,
        class: "text-center",
      },
      {
        key: "start_date",
        label: "Data Início",
        sortable: false,
        class: "text-center",
      },
      {
        key: "end_date",
        label: "Data Fim",
        sortable: false,
        class: "text-center",
      },
      // {
      //   key: "classroom_name",
      //   label: "Turma",
      //   sortable: false,
      //   class: "text-center",
      // },
      // {
      //   key: "product_category_name",
      //   label: "Categoria",
      //   sortable: false,
      //   class: "text-center",
      // },
      // {
      //   key: "reads",
      //   label: "Visto",
      //   sortable: false,
      //   class: "text-center",
      //   thStyle: "width: 90px",
      // },
      {
        key: "created_at",
        label: "Cadastrado em",
        sortable: false,
        class: "text-center",
        thStyle: "width: 120px",
      },
      {
        key: "actions",
        label: "",
        thClass: "text-center td-actions",
        thStyle: "width: 80px",
      },
    ],
    filteredSlugs: [],
    allSlugs: [],
    mode: "create",
    optionsProducts: [],
    optionsSlugs: [],
    optionsClassrooms: [],
    optionsTypes: [],
    optionsStatus: [
      {
        id: 1,
        name: "Ativo",
      },
      {
        id: 0,
        name: "Inativo",
      },
    ],
  }),
  validations: {
    currentItem: {
      title: { required },
      status: { required },
      type: { required },
      slug: { required },
      active_date: { required },
    },
  },
  async mounted() {
    await Promise.all([
      this.getData(),
      this.fetchWarningTypes(),
      this.fetchAllSlugs(),
    ]);
    this.$root.$on("open:modal-create-warning", () => this.openCreateModal());
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-warning");
  },
  methods: {
    async fetchAllSlugs() {
      this.$store
        .dispatch("Warning/searchSlugs")
        .then((res) => {
          this.allSlugs = res;
          this.filteredSlugs = res;
        })
        .catch(() => {
          this.allSlugs = [];
          this.filteredSlugs = [];
        });
    },
    async productSelected(event) {
      if (event && event.product_category_id === 1) {
        this.$store
          .dispatch("Classroom/allOfProduct", event.code)
          .then((data) => {
            for (let index = 0; index < data.length; index++) {
              this.optionsClassrooms.push({
                title: `${data[index].name} - ${moment(
                  data[index].start_time,
                  "HH:mm:ss"
                ).format("HH:mm")} às ${moment(
                  data[index].end_time,
                  "HH:mm:ss"
                ).format("HH:mm")}`,
                code: data[index].classroom_id,
              });
            }
          });
      }
    },
    async fetchWarningTypes() {
      this.optionsTypes = [];
      this.$store.dispatch("Warning/fetchWarningTypes").then((data) => {
        this.optionsTypes = data;
      });
    },
    async fetchSlugs(search) {
      try {
        if (search.length > 2) {
          this.filteredSlugs = this.allSlugs.filter((slug) =>
            slug.includes(search)
          );
        }
      } catch (error) {
        this.filteredSlugs = [];
      }
    },
    async fetchProducts(term) {
      this.optionsProducts = [];
      if (term.length > 2) {
        this.$store
          .dispatch("Product/search", { term: term, type: 0 })
          .then((res) => {
            this.optionsProducts = res;
          });
      }
    },
    setTitleModal() {
      if (this.mode === "create") {
        return "Cadastrar aviso";
      } else if (this.mode === "update") {
        return "Editar aviso";
      }
      return "Visualizar aviso";
    },
    async submitWarning(action) {
      this.$v.$touch();
      this.submited = true;

      if (this.$v.$error) {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
        this.submited = false;
        return;
      }

      try {
        await this.$store.dispatch(`Warning/${action}`, this.currentItem);

        const successMessage = "Operação realizada com sucesso!";
        this.notify(successMessage, "UserCheckIcon", "success");

        this.$bvModal.hide("modal-create-warning");
        await this.getData();

        this.clearForm();
        this.$v.$reset();
      } catch (err) {
        const errorMessage = "Erro ao realizar a operação";
        this.notifyDefault("error", errorMessage);
      } finally {
        this.submited = false;
      }
    },
    openCreateModal() {
      this.mode = "create";
      this.$v.$reset();
      this.currentItem = {};
      this.$bvModal.show("modal-create-warning");
    },
    openEditModal(item) {
      this.mode = "update";
      this.currentItem = {
        ...item,
        type: item.warning_type_id,
        active_date: `${item.start_date} até ${item.end_date}`,
      };
      this.$v.$reset();
      this.$bvModal.show("modal-create-warning");
    },
    openViewModal(item) {
      this.mode = "view";
      this.currentItem = { ...item, type: item.warning_type_id };
      this.$bvModal.show("modal-create-warning");
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    async getData() {
      this.$store
        .dispatch("Warning/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((data) => {
          this.warnings = data;

          this.rowsTable = this.warnings.data.length;
          this.totalRows = this.warnings.meta.total;
          this.currentPage = this.warnings.meta.current_page;
        })
        .catch((err) => {
          this.warnings = [];
          this.rowsTable = 0;
          this.totalRows = 0;
          this.currentPage = 1;
        });
    },
    clearForm() {
      this.currentItem = {
        title: "",
        stauts: "",
        type: "",
        slug: "",
        active_date: "",
      };
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    hideModal() {
      this.clearForm();
      this.$v.$reset();
      this.$bvModal.hide("modal-create-warning");
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar o usuário caso não tenha nenhum cadastro vinculado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Warning/delete", { uuid: uuid }).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
