var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c("div", { staticClass: "form-row justify-content-between" }, [
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      options: _vm.perPageOptions,
                      clearable: false,
                    },
                    on: { input: _vm.setPerPageSelected },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-md-4" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter,
                      expression: "filter",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "search", placeholder: "Pesquisar..." },
                  domProps: { value: _vm.filter },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.filter = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              "no-border-collapse": true,
              items: _vm.warnings.data,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              "empty-filtered-text": "Nenhum registro encontrado",
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
              striped: "",
              hover: "",
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(start_date)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .moment(data.item.start_date)
                            .format("DD/MM/YYYY HH:mm")
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(end_date)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .moment(data.item.end_date)
                            .format("DD/MM/YYYY HH:mm")
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (data) {
                  return [
                    _c(
                      "b-badge",
                      {
                        attrs: {
                          variant: data.item.status ? "success" : "danger",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(data.item.status ? "Ativo" : "Inativo") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(warning_type)",
                fn: function (data) {
                  return [
                    _c(
                      "b-badge",
                      { style: { backgroundColor: data.item.warning_color } },
                      [_vm._v(" " + _vm._s(data.item.warning_type) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-content-start",
                        staticStyle: { "column-gap": "15px" },
                      },
                      [
                        _c("b-avatar", {
                          attrs: {
                            src: data.item.product_image,
                            variant: "light-primary",
                            rounded: "",
                          },
                        }),
                        _c("div", { staticClass: "chat-info flex-grow-1" }, [
                          _c("h5", { staticClass: "mb-0" }, [
                            _vm._v(" " + _vm._s(data.item.title) + " "),
                          ]),
                          _c("p", { staticClass: "card-text text-truncate" }, [
                            _vm._v(
                              " " +
                                _vm._s(data.item.product_category_initials) +
                                " - " +
                                _vm._s(data.item.product_title) +
                                " "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("datePT")(data.item.created_at)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(reads)",
                fn: function (data) {
                  return [
                    _c("b-badge", { attrs: { variant: "light-dark" } }, [
                      _vm._v(" " + _vm._s(data.item.reads) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor text-danger",
                          attrs: { icon: "TrashIcon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete(data.item.uuid)
                            },
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: { icon: "EditIcon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.openEditModal(data.item)
                            },
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: { icon: "EyeIcon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.openViewModal(data.item)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "mx-2 mb-2 mt-2 paginate-area" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-start",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("span", {}, [
                        _vm._v(
                          " Foram encontrados " +
                            _vm._s(_vm.totalRows) +
                            " resultados "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-end",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "my-1",
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          limit: 10,
                          "first-number": "",
                          "last-number": "",
                          "prev-class": "prev-item",
                          "next-class": "next-item",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.updatePage()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prev-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronLeftIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "next-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronRightIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            id: "modal-create-warning",
            title: _vm.setTitleModal(),
            "hide-footer": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c("b-card-text", [
            _c(
              "form",
              {
                ref: "formCreateUser",
                staticClass: "p-0",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitWarning(_vm.mode)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Título "),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentItem.title,
                            expression: "currentItem.title",
                          },
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.$v.currentItem.title.$error,
                        },
                        attrs: {
                          disabled: _vm.mode === "view",
                          type: "text",
                          minlength: "5",
                          name: "title",
                        },
                        domProps: { value: _vm.currentItem.title },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentItem,
                              "title",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Descrição "),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentItem.description,
                            expression: "currentItem.description",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          disabled: _vm.mode === "view",
                          type: "text",
                          minlength: "5",
                          name: "description",
                        },
                        domProps: { value: _vm.currentItem.description },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentItem,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Status "),
                        ]),
                        _c("v-select", {
                          class: {
                            "is-invalid": _vm.$v.currentItem.status.$error,
                          },
                          attrs: {
                            disabled: _vm.mode === "view",
                            options: _vm.optionsStatus,
                            placeholder: "Selecione o status",
                            label: "name",
                            clearable: false,
                            reduce: function (option) {
                              return option.id
                            },
                          },
                          model: {
                            value: _vm.currentItem.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentItem, "status", $$v)
                            },
                            expression: "currentItem.status",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Tipos "),
                        ]),
                        _c(
                          "v-select",
                          {
                            class: {
                              "is-invalid": _vm.$v.currentItem.type.$error,
                            },
                            attrs: {
                              disabled: _vm.mode === "view",
                              options: _vm.optionsTypes,
                              placeholder: "Selecione o tipo",
                              label: "name",
                              clearable: false,
                              reduce: function (option) {
                                return option.id
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "option",
                                fn: function (option) {
                                  return [
                                    _c(
                                      "b-badge",
                                      {
                                        style: {
                                          backgroundColor:
                                            option.color_hex || "#ffc107",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(option.name) + " ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.currentItem.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentItem, "type", $$v)
                              },
                              expression: "currentItem.type",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Slug "),
                      _c(
                        "b-form-group",
                        { attrs: { "label-for": "slug" } },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-invalid": _vm.$v.currentItem.slug.$error,
                            },
                            attrs: {
                              id: "slug",
                              placeholder: "Crie ou selecione um slug...",
                              list: "slug-options",
                            },
                            on: { input: _vm.fetchSlugs },
                            model: {
                              value: _vm.currentItem.slug,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentItem, "slug", $$v)
                              },
                              expression: "currentItem.slug",
                            },
                          }),
                          _c(
                            "datalist",
                            { attrs: { id: "slug-options" } },
                            _vm._l(_vm.filteredSlugs, function (option) {
                              return _c("option", {
                                key: option,
                                domProps: { value: option },
                              })
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Data de Exibição "),
                        ]),
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            disabled: _vm.mode === "view",
                            placeholder: "Exibir em",
                            config: {
                              mode: "range",
                              dateFormat: "Y-m-d",
                              altFormat: "d/m/Y",
                              locale: "pt",
                            },
                          },
                          model: {
                            value: _vm.currentItem.active_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentItem, "active_date", $$v)
                            },
                            expression: "currentItem.active_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v(" Imagem ")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentItem.image,
                            expression: "currentItem.image",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          disabled: _vm.mode === "view",
                          name: "text",
                          placeholder: "https://...",
                        },
                        domProps: { value: _vm.currentItem.image },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentItem,
                              "image",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v(" Link ")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentItem.link,
                            expression: "currentItem.link",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "https://",
                          disabled: _vm.mode === "view",
                          name: "link",
                        },
                        domProps: { value: _vm.currentItem.link },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentItem,
                              "link",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" Produto "),
                        ]),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              disabled: _vm.mode === "view",
                              "item-value": "code",
                              placeholder: "Digite o título",
                              options: _vm.optionsProducts,
                            },
                            on: {
                              search: _vm.fetchProducts,
                              input: _vm.productSelected,
                            },
                            model: {
                              value: _vm.currentItem.product,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentItem, "product", $$v)
                              },
                              expression: "currentItem.product",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              disabled: _vm.mode === "view",
                              "item-value": "code",
                              placeholder: "Digite o título",
                              options: _vm.optionsClassrooms,
                            },
                            model: {
                              value: _vm.currentItem.classroom,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentItem, "classroom", $$v)
                              },
                              expression: "currentItem.classroom",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "form-row justify-content-end mt-2 pb-0" },
                  [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(186, 191, 199, 0.15)",
                              expression: "'rgba(186, 191, 199, 0.15)'",
                              modifiers: { 400: true },
                            },
                          ],
                          staticClass: "btn btn-block btn-outline-secondary",
                          attrs: { type: "button", block: "" },
                          on: {
                            click: function ($event) {
                              return _vm.hideModal()
                            },
                          },
                        },
                        [_vm._v(" Fechar ")]
                      ),
                    ]),
                    _vm.mode !== "view"
                      ? _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(255, 255, 255, 0.15)",
                                    expression: "'rgba(255, 255, 255, 0.15)'",
                                    modifiers: { 400: true },
                                  },
                                ],
                                staticClass: "mr-2",
                                attrs: {
                                  variant: "success",
                                  type: "submit",
                                  block: "",
                                  disabled: _vm.submited,
                                },
                              },
                              [
                                _vm.submited
                                  ? _c(
                                      "div",
                                      [
                                        _c("b-spinner", {
                                          attrs: {
                                            small: "",
                                            variant: "light",
                                          },
                                        }),
                                        _vm._v(" Verificando... "),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.mode === "create"
                                              ? "Cadastrar"
                                              : "Salvar Alterações"
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm.item
        ? _c(
            "b-modal",
            {
              attrs: {
                centered: "",
                id: "modal-show-warning",
                "hide-footer": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-title",
                    fn: function () {
                      return [
                        _vm._v(" " + _vm._s(_vm.item.title) + " "),
                        _c(
                          "b-badge",
                          {
                            staticClass: "ml-2",
                            style: {
                              backgroundColor:
                                _vm.item.warning_color || "#ffc107",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.item.warning_type) + " ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1526378973
              ),
            },
            [
              _c(
                "b-card",
                [
                  _c("b-card-text", [
                    _c("form", { ref: "formCreateUser", staticClass: "p-0" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Descrição:")]),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.item.description),
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Produto:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.item.product_title || "Não informado"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Categoria:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.item.product_category_name ||
                                    "Não informado"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Período:")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDate(_vm.item.start_date)) +
                                " - " +
                                _vm._s(_vm.formatDate(_vm.item.end_date)) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Criado em:")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDate(_vm.item.created_at)) +
                                " "
                            ),
                          ]),
                        ]),
                        _vm.item.link
                          ? _c("div", { staticClass: "col-md-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        href: _vm.item.link,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(" Acessar Link ")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }